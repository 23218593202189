//ALL THE KEYS FOR THE QUERY PARAMS

// CREATORS SEARCH
export const CREATORS_NETWORK = 'network';
export const CREATORS_CUSTOM_SEARCH = 'filter[custom_search]';
export const CREATORS_LOCATION_SEARCH = 'filter[location]';
export const CREATORS_COORDINATES_SEARCH = 'filter[coordinates]';
export const CREATORS_EXCLUDE_COORDINATES_SEARCH = 'filter[exclude_coordinates]';
export const CREATORS_FILTER_FOLLOWERS = 'filter[followers_filter]';
export const CREATORS_FILTER_ENGAGEMENT = 'filter[engagement_percentage_filter]';
export const CREATORS_FILTER_AVG_LIKES_PER_POST = 'filter[avg_likes_per_post_filter]';
export const CREATORS_FILTER_AVG_COMMENTS_PER_POST = 'filter[avg_comments_per_post_filter]';
export const CREATORS_FILTER_ESTIMATED_REACH = 'filter[estimated_reach_filter]';
export const CREATORS_FILTER_LAST_POST = 'filter[last_post_filter]';
export const CREATORS_FILTER_AVERAGE_VIDEO_VIEWS_COUNT = 'filter[average_video_views_count]';
export const CREATORS_FILTER_TOTAL_VIDEO_VIEWS_COUNT = 'filter[total_video_views_count]';
export const CREATORS_FILTER_GENDER = 'filter[gender]';
export const CREATORS_FILTER_VERIFIED = 'filter[verified]';
export const CREATORS_FILTER_CONTACTABLE = 'filter[contactable]';
export const CREATORS_FILTER_EXCLUDE_BRANDS = 'filter[exclude_brands]';
export const CREATORS_FILTER_MENTIONS = 'filter[mentions]';
export const CREATORS_FILTER_EXCLUDE_MENTIONS = 'filter[exclude_mentions]';
export const CREATORS_FILTER_HASHTAGS = 'filter[hashtags]';
export const CREATORS_FILTER_EXCLUDE_HASHTAGS = 'filter[exclude_hashtags]';
export const CREATORS_FILTER_AUDIENCE_AGE_FILTER = 'filter[audience_age_filter]';
export const CREATORS_FILTER_AUDIENCE_LOC_FILTER = 'filter[audience_loc_filter]';
export const CREATORS_FILTER_AUDIENCE_CAT_FILTER = 'filter[audience_cat_filter]';
export const CREATORS_FILTER_AUDIENCE_LAN_FILTER = 'filter[audience_lan_filter]';
export const CREATORS_FILTER_AUDIENCE_GEN_FILTER = 'filter[audience_gen_filter]';
export const CREATORS_FILTER_AUDIENCE_IS_REAL_PERCENTAGE_FILTER = 'filter[audience_is_real_percentage_filter]';
export const CREATORS_FILTER_IS_MOONIO = 'filter[is_moonio]';
export const CREATORS_FILTER_LOOKALIKE = 'filter[lookalike]';
export const CREATORS_FILTER_EXCLUDE_CREATORS = 'filter[exclude_creators]';
export const CREATORS_FILTER_EXCLUDED_LISTS = 'filter[exclude_lists]';
export const CREATORS_FILTER_EXCLUDE_KEYWORDS = 'filter[exclude_keywords]';
export const CREATORS_FILTER_EXCLUDE_LOCATION = 'filter[exclude_location]';
export const CREATORS_FILTER_AQS = 'filter[aqs]';
export const CREATORS_FILTER_FOLLOWERS_GROWTH = 'filter[followers_growth]';
export const CREATORS_FILTER_COST_PER_POST = 'filter[cost_per_post]';
export const EXACT_SEARCH_MATCH = 'filter[exact_search_match]';

// MY_PROPOSALS
export const PROPOSALS_NETWORK = 'filter[network]';
export const CREATORS_RESULTS_PAGE = 'page';
export const CREATORS_RESULTS_ORDER = 'order';

// Query param for the id of lists, cards and pipelines
export const ID = 'id';

export const PROFILE_SETTINGS = 'profile_settings';

// CONFIGURATION FOR THE DATA TYPES OF THE QUERY PARAMS
const paramsConfig = {
  [CREATORS_NETWORK]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_CUSTOM_SEARCH]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_EXCLUDE_KEYWORDS]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_LOCATION_SEARCH]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_FILTER_EXCLUDE_LOCATION]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_COORDINATES_SEARCH]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_EXCLUDE_COORDINATES_SEARCH]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_FILTER_FOLLOWERS]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_ENGAGEMENT]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_AVG_LIKES_PER_POST]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_AVG_COMMENTS_PER_POST]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_GENDER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_ESTIMATED_REACH]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_LAST_POST]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_AVERAGE_VIDEO_VIEWS_COUNT]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_TOTAL_VIDEO_VIEWS_COUNT]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_VERIFIED]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_CONTACTABLE]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_EXCLUDE_BRANDS]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_MENTIONS]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_EXCLUDE_MENTIONS]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_HASHTAGS]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_FILTER_EXCLUDE_HASHTAGS]: {
    isArray: true,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_AGE_FILTER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_LOC_FILTER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_CAT_FILTER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_LAN_FILTER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_GEN_FILTER]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_AUDIENCE_IS_REAL_PERCENTAGE_FILTER]: {
    isArray: true,
    type: 'number',
  },
  [CREATORS_FILTER_IS_MOONIO]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_FILTER_LOOKALIKE]: {
    isArray: false,
    type: 'string',
  },
  [CREATORS_RESULTS_PAGE]: {
    isArray: false,
    type: 'number',
  },
  [CREATORS_RESULTS_ORDER]: {
    isArray: false,
    type: 'string',
  },
  [ID]: {
    isArray: false,
    type: 'number',
  },
  [PROPOSALS_NETWORK]: {
    isArray: true,
    type: 'string',
  },
  [PROFILE_SETTINGS]: {
    isArray: false,
    type: 'string',
  },
};

export default paramsConfig;
