import React, { createContext, useMemo, useReducer } from 'react';
import useUrlQuery from 'customHooks/use-url-query';
import keyMapping from '../helpers/key-mapping';

export const FiltersCtx = createContext();

const initialState = {
  followersFilter: undefined,
  engagementFilter: undefined,
  mentionsFilter: undefined,
  hashtagsFilter: undefined,
  verifiedFilter: undefined,
  contactableFilter: undefined,
  optedInFilter: undefined,
  brandsFilter: undefined,
  creatorsFilter: undefined,
  audienceAgeFilter: undefined,
  audienceLocationFilter: undefined,
  audienceCatFilter: undefined,
  audienceLanguageFilter: undefined,
  audienceGenderFilter: undefined,
  audienceAuthenticityFilter: undefined,
  customSearch: undefined,
  coordinatesFilter: undefined,
  excludeCoordinatesFilter: undefined,
  locationFilter: undefined,
  lookalikeFilter: undefined,
  avgLikesPerPostFilter: undefined,
  avgCommentsPerPostFilter: undefined,
  estimatedReachFilter: undefined,
  genderFilter: undefined,
  averageVideoViewsCountFilter: undefined,
  totalVideoViewsCountFilter: undefined,
  excludedListsFilter: undefined,
  excludeKeywordsFilter: undefined,
  excludeLocationFilter: undefined,
  excludeHashtagsFilter: undefined,
  excludeMentionsFilter: undefined,
  aqsFilter: undefined,
  followersGrowthFilter: undefined,
  costPerPostFilter: undefined,
  exactSearchMatch: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FOLLOWERS_FILTER':
      return {
        ...state,
        followersFilter: action.payload,
      };
    case 'SET_ENGAGEMENT_FILTER':
      return {
        ...state,
        engagementFilter: action.payload,
      };
    case 'SET_MENTIONS_FILTER':
      return {
        ...state,
        mentionsFilter: action.payload,
      };
    case 'SET_EXCLUDE_MENTIONS_FILTER':
      return {
        ...state,
        excludeMentionsFilter: action.payload,
      };
    case 'SET_HASHTAGS_FILTER':
      return {
        ...state,
        hashtagsFilter: action.payload,
      };
    case 'SET_EXCLUDE_HASHTAGS_FILTER':
      return {
        ...state,
        excludeHashtagsFilter: action.payload,
      };
    case 'SET_VERIFIED_FILTER':
      return {
        ...state,
        verifiedFilter: action.payload,
      };
    case 'SET_CONTACTABLE_FILTER':
      return {
        ...state,
        contactableFilter: action.payload,
      };
    case 'SET_OPTEDIN_FILTER':
      return {
        ...state,
        optedInFilter: action.payload,
      };
    case 'SET_BRANDS_FILTER':
      return {
        ...state,
        brandsFilter: action.payload,
      };
    case 'SET_CREATORS_FILTER':
      return {
        ...state,
        creatorsFilter: action.payload,
      };
    case 'SET_AUDIENCE_AGE_FILTER':
      return {
        ...state,
        audienceAgeFilter: action.payload,
      };
    case 'SET_AUDIENCE_LOCATION_FILTER':
      return {
        ...state,
        audienceLocationFilter: action.payload,
      };
    case 'SET_AUDIENCE_CAT_FILTER':
      return {
        ...state,
        audienceCatFilter: action.payload,
      };
    case 'SET_AUDIENCE_LANGUAGE_FILTER':
      return {
        ...state,
        audienceLanguageFilter: action.payload,
      };
    case 'SET_AUDIENCE_GENDER_FILTER':
      return {
        ...state,
        audienceGenderFilter: action.payload,
      };
    case 'SET_AUDIENCE_AUTHENTICITY_FILTER':
      return {
        ...state,
        audienceAuthenticityFilter: action.payload,
      };
    case 'SET_LOOKALIKE_FILTER':
      return {
        ...state,
        lookalikeFilter: action.payload,
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customSearch: action.payload,
      };
    case 'SET_EXCLUDE_KEYWORDS_FILTER':
      return {
        ...state,
        excludeKeywordsFilter: action.payload,
      };
    case 'SET_LOCATION_FILTER':
      return {
        ...state,
        locationFilter: action.payload,
      };
    case 'SET_EXCLUDE_LOCATION_FILTER':
      return {
        ...state,
        excludeLocationFilter: action.payload,
      };
    case 'SET_COORDINATES_FILTER':
      return {
        ...state,
        coordinatesFilter: action.payload,
      };
    case 'SET_EXCLUDE_COORDINATES_FILTER':
      return {
        ...state,
        excludeCoordinatesFilter: action.payload,
      };
    case 'SET_AVG_LIKES_PER_POST_FILTER':
      return {
        ...state,
        avgLikesPerPostFilter: action.payload,
      };
    case 'SET_AVG_COMMENTS_PER_POST_FILTER':
      return {
        ...state,
        avgCommentsPerPostFilter: action.payload,
      };
    case 'SET_ESTIMATED_REACH_FILTER':
      return {
        ...state,
        estimatedReachFilter: action.payload,
      };
    case 'SET_LAST_POST_FILTER':
      return {
        ...state,
        lastPostFilter: action.payload,
      };
    case 'SET_GENDER_FILTER':
      return {
        ...state,
        genderFilter: action.payload,
      };
    case 'SET_AVERAGE_VIDEO_VIEWS_COUNT_FILTER':
      return {
        ...state,
        averageVideoViewsCountFilter: action.payload,
      };
    case 'SET_TOTAL_VIDEO_VIEWS_COUNT_FILTER':
      return {
        ...state,
        totalVideoViewsCountFilter: action.payload,
      };
    case 'SET_EXCLUDE_LISTS_FILTER':
      return {
        ...state,
        excludedListsFilter: action.payload,
      };
    case 'SET_AQS_FILTER':
      return {
        ...state,
        aqsFilter: action.payload,
      };
    case 'SET_FOLLOWERS_GROWTH_FILTER':
      return {
        ...state,
        followersGrowthFilter: action.payload,
      };
    case 'SET_COST_PER_POST_FILTER':
      return {
        ...state,
        costPerPostFilter: action.payload,
      };
    case 'RESET_FILTERS_PARTIAL':
      return {
        ...state,
        ...Object.fromEntries(action.payload.map((key) => [key, undefined])),
      };
    case 'RESET_FILTERS':
      return {
        ...Object.fromEntries(Object.keys(initialState).map((key) => [key, undefined])),
      };
    case 'RESET_STORE':
      return initialState;
    case 'SET_EXACT_SEARCH_MATCH':
      return {
        ...state,
        exactSearchMatch: action.payload,
      };
    default:
      return state;
  }
}

export function FiltersCtxProvider({ children }) {
  const { query } = useUrlQuery();
  const initialStateFromQuery = Object.fromEntries(
    Object.entries(keyMapping).map(([contextKey, queryKey]) => [contextKey, query[queryKey]]),
  );

  const [state, dispatch] = useReducer(reducer, initialStateFromQuery);

  const appliedFiltersCount = useMemo(() => {
    let count = Object.values(state).filter((value) => value !== undefined).length;

    if (
      (state.locationFilter !== undefined && state.coordinatesFilter !== undefined) ||
      (state.excludeLocationFilter !== undefined && state.excludeCoordinatesFilter !== undefined)
    ) {
      count -= 1;
    }

    return count;
  }, [state]);

  const value = { state, dispatch, appliedFiltersCount };

  return <FiltersCtx.Provider value={value}>{children}</FiltersCtx.Provider>;
}
